import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';


export const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const btn =  document.querySelector('#btn-send');
    //btn.style.cssText += 'display:none';

    emailjs.sendForm('service_eqq2j6m', 'wed_template', form.current, 'bjFfwGfZeeiTm-oiN')
      .then((result) => {
          console.log(result.text);
          //alert('Quote Sent!');
          document.querySelector('#btn-send').value = 'Quote done!'
          btn.disabled = true;
          document.getElementById("wedding-form").reset();
          
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail} id='wedding-form' className='form'>
        <div className='section-container'>
            <p className='section-sub'>          
            Personal Info
            </p>
            <div className='underline-sub'></div> 
        </div>
        <div className='personal-info-section incomplete-box'>
            
            <div className="field name">
                <label for="from_name">Name</label>
                <input type="text" name="from_name" id="from_name"/>
            </div>
            <div className="field country">
                <label for="country">Country</label>
                <input type="text" name="country" id="country"/>
            </div>
            <div className="field email">
                <label for="reply_to">Email</label>
                <input type="email" name="reply_to" id="reply_to"/>
            </div>
            <div className="field phone">
                <label for="phone">Phone</label>
                <input type="text" name="phone" id="phone"/>
            </div>
            <div className="field pref-contact">
                <label for="prefered_contact">Prefered Contact</label>
                <select name="prefered_contact" id="prefered_contact">
                    <option value="Email">Email</option>
                    <option value="Phone">Phone</option>
                </select>
            </div>
        </div>
        <div className='section-container'>
            <p className='section-sub'>          
            Wedding Info
            </p>
            <div className='underline-sub'></div> 
        </div>
        <div className='wedding-info-section incomplete-box'>
        <div className='couple-section'>
                <p className='section-sub'>          
                <img src="https://firebasestorage.googleapis.com/v0/b/clubvoyagermx-292ad.appspot.com/o/Weddings%2FResources%2Fornament_left.svg?alt=media&token=8d3cb96b-f999-4598-9b99-9816d9307cfa&_gl=1*y16nge*_ga*MTcyNjkyOTUxMi4xNjk3NzYxMTg5*_ga_CW55HF8NVT*MTY5ODYwNjk0Ny4xMi4xLjE2OTg2MDcyMDYuNDkuMC4w"
                 alt='Left Ornament'/>
                Couple
                <img src="https://firebasestorage.googleapis.com/v0/b/clubvoyagermx-292ad.appspot.com/o/Weddings%2FResources%2Fornament_right.svg?alt=media&token=6337c58a-f811-4559-89cc-ff6140fe0478&_gl=1*1x0c32m*_ga*MTcyNjkyOTUxMi4xNjk3NzYxMTg5*_ga_CW55HF8NVT*MTY5ODYwNjk0Ny4xMi4xLjE2OTg2MDc3NjkuNjAuMC4w"
                alt="Right Ornament"
                />
                </p>
                <div className="field bride">
                    <label for="Bride">Name</label>
                    <input type="text" name="Bride" id="Bride"/>
                </div>
                <div className="field groom">
                    <label for="Groom">Name</label>
                    <input type="text" name="Groom" id="Groom"/>
                </div>
                <div className="field ages">
                    <label for="ages">Ages</label>
                    <select name="ages" id="ages">
                        <option value="20-30">20-30</option>
                        <option value="31-40">31-40</option>
                        <option value="41-50">41-50</option>
                        <option value="51-60">51-60</option>
                        <option value="60+">60+</option>
                </select>
                </div>

        </div>

        <div className='wedd-specs'>

        <p className='section-sub'>          
                <img src="https://firebasestorage.googleapis.com/v0/b/clubvoyagermx-292ad.appspot.com/o/Weddings%2FResources%2Fornament_left.svg?alt=media&token=8d3cb96b-f999-4598-9b99-9816d9307cfa&_gl=1*y16nge*_ga*MTcyNjkyOTUxMi4xNjk3NzYxMTg5*_ga_CW55HF8NVT*MTY5ODYwNjk0Ny4xMi4xLjE2OTg2MDcyMDYuNDkuMC4w"
                 alt='Left Ornament'/>
                Wedding
                <img src="https://firebasestorage.googleapis.com/v0/b/clubvoyagermx-292ad.appspot.com/o/Weddings%2FResources%2Fornament_right.svg?alt=media&token=6337c58a-f811-4559-89cc-ff6140fe0478&_gl=1*1x0c32m*_ga*MTcyNjkyOTUxMi4xNjk3NzYxMTg5*_ga_CW55HF8NVT*MTY5ODYwNjk0Ny4xMi4xLjE2OTg2MDc3NjkuNjAuMC4w"
                alt="Right Ornament"
                />
        </p>

        <div className='container-wed-specs'>
            <div className='first-row'>
                <div className="field wed_date">
                    <label for="wedding_date">Wedding Date</label>
                    <input type="date" name="wedding_date" id="wedding_date"/>
                </div>
                <div className="field wed-hr">
                    <label for="wedding_hour">Ceremony Hour</label>
                    <input type="time" name="wedding_hour" id="wedding_hour"/>
                </div>
                <div className="field">
                    <label for="private_reception">Do you prefer a private reception?</label>
                    <div className='prefs-reception'>

                    <label class="container">Yes
                    <input type="radio" id="Yes-PR" name="Private-Reception" value="Private reception"/>
                        <span class="checkmark"></span>
                    </label>

                    <label class="container">No
                    <input type="radio" id="No-PR" name="Private-Reception" value="No private reception"/>
                        <span class="checkmark"></span>
                    </label>

                    </div>
                </div>
                <div className="field">
                    <label for="budget">Budget (Write Current)</label>
                    <input type="text" name="budget" id="budget"/>
                </div>
            </div>
            <div className='second-row'>
                <div className="field">
                    <label for="check_in">Check In</label>
                    <input type="text" name="check_in" id="check_in"/>
                </div>
                <div className="field">
                    <label for="check_out">Check Out</label>
                    <input type="text" name="check_out" id="check_out"/>
                </div>
            </div>
        </div>
        </div>

        <div className='wedd-prefs'>

        <div className="field wed-ceremony">
            <label for="ceremony_type">Ceremony Type</label>

            <div className='options'>

            <label class="container">Official/Legal
            <input type="checkbox" name="cb-official" value="Official/Legal"/>
            <span class="checkmark"></span>
            </label>
            
            <label class="container">Symbolic
            <input type="checkbox" name="cb-Symbolic" value="Symbolic"/>
            <span class="checkmark"></span>
            </label>

            <label class="container">Catholic
            <input type="checkbox" name="cb-catholic" value="Catholic"/>
            <span class="checkmark"></span>
            </label>

            <label class="container">Vow Renewal
            <input type="checkbox" name="cb-renewal" value="Vow Renewal"/>
            <span class="checkmark"></span>
            </label>

            </div>

            <label for="other_type">Other type</label>
            <input type="text" name="other-type" id="other-type"/>
        </div>

        <div className="field wed-priorities">
            <label for="ceremony_type">Priorities</label>

            <div className='options'>

            <label class="container">Flowers
            <input type="checkbox" name="cb-flowers" value="Flowers"/>
            <span class="checkmark"></span>
            </label>
            
            <label class="container">Food & Drinks
            <input type="checkbox" name="cb-food-drinks" value="Food & Drinks"/>
            <span class="checkmark"></span>
            </label>

            <label class="container">Wedding Cake
            <input type="checkbox" name="cb-wed-cake" value="Wedding-Cake"/>
            <span class="checkmark"></span>
            </label>

            <label class="container">Photo & Video
            <input type="checkbox" name="cb-photo-video" value="Photo & Video"/>
            <span class="checkmark"></span>
            </label>

            <label class="container">Amenities
            <input type="checkbox" name="cb-amenities" value="Amenities"/>
            <span class="checkmark"></span>
            </label>

            <label class="container">Guest Experience
            <input type="checkbox" name="cb-guest-exp" value="Guest Experience"/>
            <span class="checkmark"></span>
            </label>

            </div>

            <label for="other-priorities">Other Priority</label>
            <input type="text" name="other-priorities" id="other-priorities"/>
        </div>

        </div>

        <div className='last-section'>
            <div className="field guest">
                <label for="guests">Guests</label>
                <input type="text" name="guests" id="guests"/>
            </div>
            <div className="field children">
                <label for="children">Children</label>
                <input type="text" name="children" id="children"/>
            </div>
            <div className="field">
            <label for="notes">Notes</label>
            <textarea name="notes" id='notes' className='field' />
            </div>
        </div>


        </div>
      <input type="submit" id='btn-send' value="Send" className='button' />
    </form>
  );
};
import ic_logo from '../resources/Club_Voyager_Logo.svg';
import ic_plane from '../resources/illustration_plane.svg';
import ic_plane_large from '../resources/ic_plane_large.svg';
import ic_location from '../resources/ic_location.svg'
import ic_quote from '../resources/ic_quote.svg';
import ic_experience_locations from '../resources/experience_location.jpg'
import ic_experience_romance from '../resources/experience_romance.jpeg'

import Location from '../components/Location';

import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { ContactForm } from '../components/ContactForm';

function weddings() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const locationData =[
    {
      id:1,
      imgurl:'https://firebasestorage.googleapis.com/v0/b/clubvoyagermx-292ad.appspot.com/o/Weddings%2FLocations%2Fbahia_principe_tulum_ceremony.jpg?alt=media&token=a407a962-9bd8-455d-9f30-d1fda9e17423&_gl=1*14e71x*_ga*MTcyNjkyOTUxMi4xNjk3NzYxMTg5*_ga_CW55HF8NVT*MTY5ODM1MTA5Ny42LjEuMTY5ODM1MzIxMS41MC4wLjA.',
      name: 'Bahia Principe Tulum',
      features: ['Extra double suites for every 10 paid.','Room for bride and groom suite (if availible).', 'Late Check Out (3p.m.)'],
    },
    {
      id:2,
      imgurl:'https://firebasestorage.googleapis.com/v0/b/clubvoyagermx-292ad.appspot.com/o/Weddings%2FLocations%2Fcosta_mujeres_ceremony.jpg?alt=media&token=d8de3daa-a4ca-4149-a04b-45f92d8b493d&_gl=1*7el5r2*_ga*MTcyNjkyOTUxMi4xNjk3NzYxMTg5*_ga_CW55HF8NVT*MTY5ODM1MTA5Ny42LjEuMTY5ODM1MzI0Mi4xOS4wLjA.',
      name: 'G.P. Costa Mujeres',
      features: ['¡Free Wedding!','Wedding coordinator', 'Private Dinner for guests'],
    },
    {
      id:3,
      imgurl:'https://firebasestorage.googleapis.com/v0/b/clubvoyagermx-292ad.appspot.com/o/Weddings%2FLocations%2Friviera_ceremony.png?alt=media&token=de24e11e-0be8-42c4-a244-4b055acd2eab&_gl=1*7vgio9*_ga*MTcyNjkyOTUxMi4xNjk3NzYxMTg5*_ga_CW55HF8NVT*MTY5ODM1MTA5Ny42LjEuMTY5ODM1MzkxMi4yNS4wLjA.',
      name: 'G.P. Riviera Maya',
      features: ['Special setup for ceremony','Courtesy suite for couple and 50% Off 2 more suites', 'Wedding coordinator'],
    },
    {
      id:4,
      imgurl:'https://firebasestorage.googleapis.com/v0/b/clubvoyagermx-292ad.appspot.com/o/Weddings%2FLocations%2Fvallarta_ceremony.jpg?alt=media&token=311d9dba-1726-49f7-9f0c-d3c2a6c9246d&_gl=1*wu9fc1*_ga*MTcyNjkyOTUxMi4xNjk3NzYxMTg5*_ga_CW55HF8NVT*MTY5ODM1MTA5Ny42LjEuMTY5ODM1MzMzOC42MC4wLjA.',
      name: 'G.P. Vallarta',
      features: ['Courtesy suite for couple','50% Off 2 double suites', 'Wedding cake'],
    },
  ];

  const locations = locationData.map( item =>(
    <Location name = {item.name} imgurl = {item.imgurl} features= {item.features}/>
))

  return (
    <div className='weddings-container'>
          <div className="main-section">
      <div className="left-panel">
          <div className="header-container">
            <div className="brand-container">
              <h1 className='header-cursive'>Weddings</h1>
              <p className='header-sub'>By Club Voyager</p>
            </div>
            <div className="arrow-left"></div>
          </div>
          <div className="arrow-up"></div>
      </div>
      <div className="right-panel">
        <div className='header-container'>
          <div className="title-container">
              <h1 className='header'>Your first chapter...</h1>
              <p className='header-sub left-bleed'>The right place, on the right direction</p>
          </div>
          <img src={ic_plane} className='ic_plane' alt='Illustration of a plane'/>
        </div>
        <div className='section-container'>
        <p className='section-sub'>          
        <img src={ic_location} className='ic_location' alt='illustration of a map pin'/>
        Locations
        </p>
        <div className='underline-sub'></div> 
        </div>
        <div className="carousel-container">
        <Carousel responsive={responsive}>
            {locations}
        </Carousel> 
        <div className='landing-bottom'>
          <div className='arrow-down'></div>
        </div>
        </div>
      </div>
      
    </div>
    <div className='experience-section'>
    <div className='title-container'>
    <img src={ic_plane_large} className='ic_plane_large' alt='Illustration of a plane'/>
    <h1 className='header'>Experience the romance</h1>
    </div>
    <img src={ic_experience_locations} className='img_exp_loc' alt='Illustration of a couple in front of the beach'/>
    <p className='p_desc_loc'>Fabulous ceremony locations. Sweeping ocean views. The flexibility to tailor every detail to your wishes</p>
    <img src={ic_experience_romance } className='img_exp_rom' alt='Illustration of a couple'/>
    <p className='p_desc_rom'>Five-star luxury experiences, unmatched amenities, and incredible value for you and your guests. 
      Most importantly, all the planning is managed by your own Wedding Coordinator, 
      so you can focus on loving every minute of this sweet journey.</p>
    </div>
    <div className='golden-div'></div>
    <div className='why-section'>
      <div className='title-container'>
      <h1 className='header'>Why us?</h1>
      <p>We know your celebration is more than just a big day. It's an exciting new chapter, full of dreams that are one-of-a-kind.</p>
      </div>
      <div className='company-desc'>
      <img src={ic_logo} className='company-logo' alt='Club Voyager México Logo'/>
      <p>We don't believe in one-size-fits-all itineraries. Your journey should reflect your individuality and dreams. 
        Our team of experienced travel experts takes the time to understand your preferences, interests, 
        and goals to curate a custom itinerary that's just right for you.
      </p>
      </div>
      <div className='reviews-section'>
        <div className='title-container'>
        <img src={ic_quote} className='ic_quote' alt='Quote icon'/>
        <h3>Reviews</h3>
        </div>
        <ul>
          <li>Excellent Service, they guided me to choose the right option</li>
          <li>Great attention!</li>
        </ul>
      </div>
    </div>
    <div className='contact-section'>
      <div className='title-container'>
        <h1 className='header'>Contact</h1>
      </div>
      <ContactForm/>
    </div>
    <div className='footer'>
      <h3>Club Voyager México</h3>
      <p>63th Street Between 64th Street and 66th Street N. 528, Centro. P.C.: 97000., Mérida, Mexico</p>
      <p></p>
      <a href="tel:999-635-3477">999 635 3477</a>
    </div>
    </div>

  );
}

export default weddings;

//import BaseBtn from './BaseBtn'

//import img_bahia_prince from '../resources/bahia_principe_tulum_ceremony.jpg'

// <BaseBtn btn_name= 'See more' className='card-btn'></BaseBtn>


export default function Location (props) {

    const features = props.features.map( item =>(
        <li className='feature-item'>{item}</li>
    ))

    return (
    <div className="card">
        <img className="card-img" src={props.imgurl} alt='Bahia Principe at Tulum illustration'/>
        <div className='description-box'>
            <h2 className='card-title'>{props.name}</h2>
            <ul className='card-features'>
                {features}
            </ul>
        </div>
    </div>
    )
  }


